import axios from "axios";

let URL_BASE = "http://localhost:8887"

if (process.env.NODE_ENV == 'development') {
    // URL_BASE = "http://localhost:8888" 
    URL_BASE = "http://62.234.19.18:8888" 
  } else if (process.env.NODE_ENV == 'production') {
    // URL_BASE = "http://62.234.19.18:8888" 
    URL_BASE = "http://120.78.182.65:8888" 
    // URL_BASE = "http://192.168.101.85:8888" 
  }

var Api = {
    AddStockPresubscribe: "/v1/stock/presubscribe/add",

    // GetStockList: "/v1/stock/list",
    // CreateStockItem :'/v1/stock/create',
    // DeleteStockItem :'/v1/stock/delete',
    // AddStockItem :'/v1/stock/action/add',
    // UpdateStockItem :'/v1/stock/action/update',

}

export function addStockPresubscribe(date: string, stockId :string) {
  console.log("api2: addStockPresubscribe" + date + " " + stockId)
  return axios.post(URL_BASE + Api.AddStockPresubscribe+ "/" + date + "/" + stockId );
}
