
  import {
    getStockBrokenUpLimit925,
    getLatestRiseDate,
    getUpLimitVolumeInfo,
  } from '../api/api2';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from 'element-plus';


  import * as echarts from 'echarts'
  import {
    range
  } from 'lodash-es';

  interface StockDataType {
    Date: string
    StockId: string
    StockName: string
    Price: number
    PriceDiff: number
    Rise: number
    Volume: number
    Money: number
    FirstRise: number
    LastRise: number
    RiseFeature: string
    RiseDays: number
    Money_BeforeMoney: number
    BeforeVolume: number
    BeforeMoney: number
    Shown: boolean
  }

  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        curDate: '',
        riseDate: '',
        stock925Data: [] as any,
        selStock925Data: [] as any,

        middleData: 0,
        deviationData: 0,

        showEChart: true,

        timer: 0,

        checkAll: ref(false),
        isIndeterminate: ref(true),
        checkedFeature: ref([] as any),

        checkRiseDaysAll: ref(false),
        isRiseDaysIndeterminate: ref(true),
        checkedRiseDays: ref([] as any),
        allFeatures: [] as any,
        allRiseDays: [] as any
      }
    },


    async mounted() {


      this.$data.curDate = this.getYYYYMMDD()
      const res = await getLatestRiseDate()
      if (res.data.code !== 0) {
        this.$data.riseDate = this.getYestodayYYYYMMDD()
      } else {
        const riseDate = res.data.data
        if (riseDate === this.$data.curDate) {
          this.$data.riseDate = this.getYestodayYYYYMMDD()
        } else {
          this.$data.riseDate = riseDate
        }
      }

      // this.timer = window.setInterval(this.onRefresh, 1000)
      // console.log('timer:', this.timer);

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {

      cellStyle(
        row: any,
      ) {
        if (row.row.RiseType === 1 && (row.columnIndex == 4 || row.columnIndex == 7)) {
          return 'hilight1'
        } else if (row.row.RiseType === 2 && (row.columnIndex == 4 || row.columnIndex == 7)) {
          return 'hilight2'
        } else if (row.row.type_1_2 == 1 && (row.columnIndex == 15)) {
          return 'hilight4'
        } else if (row.row.type_2_3 == 1 && (row.columnIndex == 14)) {
          return 'hilight4'
        }

      },


      getYYYYMMDD() {
        const nowDate = new Date()
        const year = nowDate.getFullYear()
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
        const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()

        return '' + year + month + day
      },

      getYestodayYYYYMMDD() {
        var day1 = new Date();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        const year = day1.getFullYear()
        const month = day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1
        const day = day1.getDate() < 10 ? '0' + day1.getDate() : day1.getDate()
        return '' + year + month + day
      },

      Fixed2(val: any) {
        return Number(val).toFixed(2);
      },

      ChangeToYi(val: any) {
        return val / 100000000.0;
      },

      handleStock925DataSelChange(e: any) {
        console.log("handleActionDataSelChange", e);
        this.$data.selStock925Data = e;
      },

      async handleCheckAllChange(val: boolean) {
        console.log('handleCheckAllChange')
        this.$data.checkedFeature = ref(val ? this.$data.allFeatures : [])
        this.$data.isIndeterminate = ref(false)
      },

      async handleCheckedFeaturesChange(value: string[]) {
        console.log('handleCheckedFeaturesChange')
        const checkedCount = value.length
        this.$data.checkAll = ref(checkedCount === this.$data.allFeatures.length)
        this.$data.isIndeterminate = ref(checkedCount > 0 && checkedCount < this.$data.allFeatures.length)
      },

      async defaultFeatureCheckBox() {
        this.$data.checkedFeature = ref(this.$data.allFeatures)
        this.$data.isIndeterminate = ref(true)
      },


      async handleCheckAllRiseDaysChange(val: boolean) {
        console.log('handleCheckAllChange')
        this.$data.checkedRiseDays = ref(val ? this.$data.allRiseDays : [])
        this.$data.isRiseDaysIndeterminate = ref(false)
      },

      async handleCheckedRiseDaysChange(value: string[]) {
        console.log('handleCheckedFeaturesChange')
        const checkedCount = value.length
        this.$data.checkRiseDaysAll = ref(checkedCount === this.$data.allRiseDays.length)
        this.$data.isRiseDaysIndeterminate = ref(checkedCount > 0 && checkedCount < this.$data.allRiseDays.length)
      },


      async defaultRiseDaysCheckBox() {
        this.$data.checkedRiseDays = ref(this.$data.allRiseDays)
        this.$data.isRiseDaysIndeterminate = ref(true)
      },

      refreshShowStockdata(stockData: StockDataType[]) {
        console.log('refreshShowStockdata')
        // const shownFeatures = this.$data.checkedFeature
        // for (const item in stockData) {
        //   item.Shown = false
        //   for (const t in shownFeatures) {
        //     if (item.RiseFeature.index(t) != -1) {
        //       item.Shown = true
        //     }
        //   }
        // }
        // console.log(this.$data.stock925Data)
      },

      getVolume(data: any, stockId: string) {
        for (const item of data) {
          if (item.StockId == stockId) {
            return {
              volume: item.UpVolume,
              volume1: item.UpVolume1,
              count: item.UpCount,
              count1: item.UpCount1,
            }
          }
        }
        return {
          volume: 0,
          volume1: 0,
          count: 0,
          count1: 0,
        }

      },


      async onSearch() {
        this.$data.stock925Data = []
        this.$data.middleData = 0
        this.$data.deviationData = 0

        console.log("curDate: ", this.$data.curDate)
        const res = await getStockBrokenUpLimit925(this.$data.riseDate, this.$data.curDate)
        console.log(res)
        let {
          code,
          data,
          msg
        } = res.data;
        if (code != 0 || data === null) {
          ElMessage({
            message: "getStock925 fail." + res.data.msg,
            type: "warning",
          });
          return;
        }

        for (const item of data) {
          item.Money_BeforeMoney = item.Money * 1.0 / (item.BeforeMoney / 10000)
          item.Volume_Before_925Volume = item.Volume / (item.Before925Volume)

          const {
            volume,
            volume1,
            count,
            count1,
          } = this.getVolume(res.data.data, item.StockId)
          if (volume > 0) {
            item.Volume_BeforeVolume = item.Volume / volume
            item.Volume_BeforeVolume1 = item.Volume_BeforeVolume * count / 240
            // item.Volume_BeforeVolume1 = item.Volume / volume1
          } else {
            item.Volume_BeforeVolume = -1
            item.Volume_BeforeVolume1 = -1
          }

        }
        this.$data.stock925Data = data;

        this.$data.allRiseDays = ['1-->2', '2-->3']
        this.defaultRiseDaysCheckBox()

        this.$nextTick(function () {
          (this.$refs.stock925DataTableRef as InstanceType <
            typeof ElTable > ).toggleAllSelection()
        })

      },

      onHideEChart() {
        this.$data.showEChart = !this.$data.showEChart
      },

      getRiseType(price: number, priceDiff: number, rise: number): number {
        let type = 0
        if (rise > 5) {
          type = 1
        }
        const oldPrice = price - priceDiff
        const riseLimit: number = Number((oldPrice * 1.10).toFixed(2))
        const riseLimitDiff = riseLimit - oldPrice

        const riseDiff = riseLimitDiff - priceDiff
        if (riseDiff <= 0.00001 && riseDiff >= -0.00001) {
          type = 2
        }
        return type
      },

      // getMorningType(lastRise: string) {
      //   if (lastRise.trim() <= '11:30:00') {
      //     return 1
      //   }
      //   return 0
      // },

      getType_1_2(v: number, item: any) {
        if ((v > 1.0) && (item.RiseType != 2) && (item.RiseDays === 1) && (item.LastRise.trim() !=
            '09:30:00')) {
          return 1
        }
        return 0
      },

      getType_2_3(v: number, item: any) {
        if ((v > 1.5) && (item.RiseType != 2) && (item.RiseDays >= 2) && (item.RiseDays <= 4)) {
          return 1
        }
        return 0
      }


    }
  });
