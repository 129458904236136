
  import {
    defineComponent,
  } from "vue";

  export default defineComponent({
    name: "HomeView",
    data() {
      return {
        showTrade: true,
        showReview: false,
        // showTrade: false,
        // showReview: true,
      };
    },

    mounted() {},

    beforeUnmount() {
      // clearInterval(this.timer)
      // this.$data.timer = 0
    },

    setup() {},

    methods: {},
  });
