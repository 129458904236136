
  import {
    getVolumePeak,
    getStockName,
  } from '../api/api';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from 'element-plus'
  import {
    id
  } from 'element-plus/es/locale';




  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        curDate: '',
        peakData: [] as any,

        timer: 0,
      }
    },


    mounted() {


      this.$data.curDate = this.getYYYYMMDD()
      this.onRefresh();
      // this.timer = window.setInterval(this.onRefresh, 1000)
      // console.log('timer:', this.timer);

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {

      onRefresh() {
        this.updateTradeData()
      },



      getYYYYMMDD() {
        const nowDate = new Date()
        const year = nowDate.getFullYear()
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
        const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()

        return '' + year + month + day
      },


      async updateTradeData() {
        this.updateVolumePeakData();
      },

      async updateVolumePeakData() {
        console.log('updateVolumePeakData')
        const ret = await getVolumePeak(this.$data.curDate)
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          const stockMap = new Map();
          const volumeData = []
          for (const item of data) {
            if (stockMap.has(item.StockId)) {
              continue
            }
            stockMap.set(item.StockId, 1)

            item.StockName = await this.getStockNameById(item.StockId)
            item.Rise = item.Rise * 100
            volumeData.push(item)
          }

          this.$data.peakData = volumeData;
        } else {
          ElMessage({
            message: 'getStockOrder fail:' + code + " " + msg,
            type: 'warning',
          })
        }
      },


      async getStockNameById(id: string): Promise < string > {
        const retStock = await getStockName(id)
        let stockName = ""
        if (retStock.data.code == 0) {
          stockName = retStock.data.data.Name
        }
        return stockName
      },
    }
  });
