
  import {
    addRiseLimit,
    uploadFile,
    getRiseLimt,
  } from '../api/api2';
  import {
    getStockName,
  } from '../api/api';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from 'element-plus'
  import {
    id
  } from 'element-plus/es/locale';
  import axios from 'axios';




  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        riseLimitData: [] as any,
        selRiseLimitData: [] as any,
        selectFile: '',
        curDate: '',

        options: [{
          value: 0,
          label: '普通'
        }, {
          value: 1,
          label: 'ST'
        }, {
          value: 2,
          label: '新股'
        }],
      }
    },


    mounted() {
      console.log('mounted')
      this.$data.curDate = this.getYYYYMMDD()
      // this.timer = window.setInterval(this.onRefresh, 1000)
      // console.log('timer:', this.timer);

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {

      tableRowClassName(
        row: any,
        index: Number,
      ) {
        console.log('tableRowClassName')
        if (row.row.Type === 1) {
          return 'warning-row'
        } else if (row.row.Type === 2) {
          return 'success-row'
        }
        return ''
      },

      getYYYYMMDD() {
        const nowDate = new Date()
        const year = nowDate.getFullYear()
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
        const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()

        return '' + year + month + day
      },

      getYestodayYYYYMMDD() {
        var day1 = new Date();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        const year = day1.getFullYear()
        const month = day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1
        const day = day1.getDate() < 10 ? '0' + day1.getDate() : day1.getDate()
        return '' + year + month + day
      },

      handleRiseLimitDataSel(e: any) {
        console.log("handleRiseLimitDataSel", e);
        this.$data.selRiseLimitData = e;
      },


      async onUpload() {
        console.log('onUpload')

        var formData = new FormData();
        formData.append("uploadFile", this.$data.selectFile);
        const res = await uploadFile(formData);
        console.log(res)

        if (res.data.code == 0) {
          this.$data.riseLimitData = res.data.data
        } else {
          ElMessage({
            message: "上传文件失败:" + res.data.code + " " + res.data.msg,
            type: "warning",
          });
        }
      },

      async onSearch() {
        const res = await getRiseLimt(this.$data.curDate)
        if (res.data.code == 0) {
          this.$data.riseLimitData = res.data.data
        } else {
          ElMessage({
            message: "getRiseLimt 失败:" + res.data.code + " " + res.data.msg,
            type: "warning",
          });
        }

      },

      onSelectFile(e: any) {
        console.log(e)
        console.log(e.target.files[0])
        this.$data.selectFile = e.target.files[0]
      },

      async onSave() {
        console.log("onSave")
        const res = await addRiseLimit(this.$data.riseLimitData)

        if (res.data.code == 0) {
          ElMessage({
            message: "更新成功:",
            type: "success",
          });
        } else {
          ElMessage({
            message: "上传文件失败:" + res.data.code + " " + res.data.msg,
            type: "warning",
          });
        }
      }

    }
  });
