
  import {
    addStockSubscribeItem,
    queryPosition,
    getPositions,
    getStockSubscribe,
    getStockName,
    getStockOrder,
    cancelStockOrderItem,
    sellStockOrderItem,
    deleteStockSubscribeItem,
    updateStockSubscribeItem,
  } from '../api/api';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from "element-plus";


  interface Tree {
    label: string
    type: number
    name: string
    count: number
    id: string
    children ? : Tree[]
  }

  const handleNodeClick = (data: Tree) => {
    console.log(data)
  }



  export default defineComponent({
    name: 'PositionStockView',
    data() {
      return {
        curDate: '',
        positionData: [] as any,

        subscribeData: [] as any,
        selsubscribeData: [] as any,

        orderData: [] as any,
        selOrderData: [] as any,
      }
    },


    async mounted() {
      console.log("Rankstockview")
      this.$data.curDate = this.getYYYYMMDD();

      this.updatePositionData()

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {
      tableRowClassName(
        row: any,
        index: Number,
      ) {
        console.log('tableRowClassName')
        if (row.row.Type === 0) {
          return 'warning-row'
        } else if (row.row.Type === 1) {
          return 'success-row'
        }
        return ''
      },
      headClass() { //表头居中显示
        return "text-align:center"
      },
      async onSearch() {
        console.log("curDate: ", this.$data.curDate)
        const res = await queryPosition()
        console.log(res)

        if (res.data.code != 0) {
          ElMessage({
            message: "getStockRank fail." + res.data.msg,
            type: "warning",
          });
          return;
        }

        // 刷新持仓
        setTimeout(this.updatePositionData, 1000)

      },
      handlePositioinSelChange(e: any) {
        console.log('handlePositioinSelChange')

      },

      handleSubscribeSelChange(e: any) {
        console.log("handleSubscribeSelChange", e);
        this.$data.selsubscribeData = e;
      },

      handleOrderSelChange(e: any) {
        console.log("handleOrderSelChange", e);
        this.$data.selOrderData = e;
      },

      async onSellStockItem(index: Number, row: any) {
        // 直接卖出
        console.log("onSellStockItem", index, row);
        if (parseInt(row.AvailablePosition) <= 0) {
          ElMessage({
            message: "不能卖出，当前余额为: " + row.AvailablePosition,
            type: "warning",
          });
          return;
        }
        const item = {
          StockId: row.StockId,
          Date: this.$data.curDate,
          Price: 0,
          Money: 0,
          Count: parseInt(row.AvailablePosition),
          Direction: 1,
          type: 101,
        }
        const res = await addStockSubscribeItem(item);

        // setTimeout(this.updateSubscribeData, 1000)
        setTimeout(this.updateOrderData, 1000)
      },

      async onSellStockItem2(index: Number, row: any) {
        // 炸板卖出
        console.log("onSellStockItem2", index, row);
        if (parseInt(row.AvailablePosition) <= 0) {
          ElMessage({
            message: "不能卖出， 余额为: " + row.AvailablePosition,
            type: "warning",
          });
          return;
        }
        const item = {
          StockId: row.StockId,
          Date: this.$data.curDate,
          Price: 0,
          Money: 0,
          Count: parseInt(row.AvailablePosition),
          Direction: 1,
          type: 100,
        }
        const res = await addStockSubscribeItem(item);

        // 刷新持仓
        setTimeout(this.updatePositionData, 1000)
      },


      async onSellStockItem2_cancel(index: Number, row: any) {
        // 炸板卖出
        console.log("onSellStockItem2_cancel", index, row);
        const res = await updateStockSubscribeItem({
          StockId: row.StockId,
          Date: this.$data.curDate,
          Money: 0,
          Count: parseInt(row.AvailablePosition),
          Price: 0,
          Type: 100,
          Strategy: 0,
          Direction: 1,
        });

        // 刷新持仓
        setTimeout(this.updatePositionData, 1000)
      },

      // async onSellStockItem3(index: Number, row: any) {
      //   console.log("onSellStockItem", index, row);
      //   const item = {
      //     StockId: row.StockId,
      //     Date: this.$data.curDate,
      //     Price: 0,
      //     Money: 0,
      //     Count: parseInt(row.AvailablePosition),
      //     Direction: 1,
      //     type: 102,
      //   }
      //   const res = await addStockSubscribeItem(item);

      //   // setTimeout(this.updateSubscribeData, 1000)
      //   setTimeout(this.updateOrderData, 1000)
      // },

      onCancelSubscribeItem(index: Number, row: any) {
        console.log("onCancelSubscribe", index, row);
        this.cancelSubscribeItem(row);
      },

      onExecuteSubscribeItem(index: Number, row: any) {
        console.log("onExecuteSubscribeItem", index, row);
        this.executeSubscribeItem(row);

        setTimeout(this.updateSubscribeData, 1000)
        setTimeout(this.updateOrderData, 1000)
      },
      onHoldSubscribeItem(index: Number, row: any) {
        console.log("onHoldSubscribeItem", index, row);
        this.holdSubscribeItem(row);

        setTimeout(this.updateSubscribeData, 1000)
      },

      onCancelOrderItem(index: Number, row: any) {
        console.log("onCancelOrder", index, row);
        this.cancelOrderItem(row);

        setTimeout(this.updateOrderData, 1000)
      },

      onSellOrderItem(index: Number, row: any) {
        console.log("onBuyOrderItem", index, row);
        this.sellOrderItem(row);

        setTimeout(this.updateOrderData, 1000)
      },

      cancelOrderItem(data: any) {
        cancelStockOrderItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          OrderAction: "cancel",
          Price: data.Price,
          Count: data.Count,
          Strategy: data.Strategy,
          OrderSysId: data.OrderSysId,
          Direction: data.Direction,
        });
      },

      sellOrderItem(data: any) {
        sellStockOrderItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          OrderAction: "buy",
          Price: data.Price,
          Count: data.Count,
          Strategy: data.Strategy,
          OrderSysId: data.OrderSysId,
          Direction: data.Direction,
        });
      },



      cancelSubscribeItem(data: any) {
        deleteStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.curEndTime,
          Action: "cancel_subscribe",
          Money: data.Money,
          Count: data.Count,
          Type: data.Type,
          Direction: data.Direction,
        });
      },

      executeSubscribeItem(data: any) {
        updateStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.EndTime,
          Money: data.Money,
          Count: data.Count,
          Price: data.Price,
          Type: data.Type,
          Strategy: 1,
          Direction: data.Direction,
        });
      },
      holdSubscribeItem(data: any) {
        updateStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.EndTime,
          Money: data.Money,
          Count: data.Count,
          Price: data.Price,
          Type: data.Type,
          Strategy: 0,
          Direction: data.Direction,
        });
      },


      onRefresh() {
        this.updateSubscribeData()
        this.updateOrderData();
      },

      async updateSubscribeData() {
        // 查询卖出的subscribe
        const ret = await getStockSubscribe(this.$data.curDate, 1);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }

          this.$data.subscribeData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },

      async updateOrderData() {
        const ret = await getStockOrder(this.$data.curDate, 1);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }

          this.$data.orderData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },


      async updatePositionData() {
        if (this.$data.curDate.length === 0) {
          this.$data.curDate = this.getYYYYMMDD()
        }

        const ret = await getPositions(this.$data.curDate);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }
          this.$data.positionData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },

      getYYYYMMDD() {
        const nowDate = new Date();
        const year = nowDate.getFullYear();
        const month =
          nowDate.getMonth() + 1 < 10 ?
          "0" + (nowDate.getMonth() + 1) :
          nowDate.getMonth() + 1;
        const day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();

        return "" + year + month + day;
      },
      async getStockNameById(id: string): Promise < string > {
        const retStock = await getStockName(id)
        let stockName = ""
        if (retStock.data.code == 0) {
          stockName = retStock.data.data.Name
        }
        return stockName
      },
    }
  });
