import axios from "axios";

let URL_BASE = "http://localhost:8888"

if (process.env.NODE_ENV == 'development') {
    URL_BASE = "http://localhost:8888" 
  } else if (process.env.NODE_ENV == 'production') {
    // URL_BASE = "http://62.234.19.18:8888" 
    URL_BASE = "http://120.78.182.65:8888" 
    // URL_BASE = "http://192.168.101.85:8888" 
  }

var Api = {
    GetEndTimeList :"/v1/endtime/list",
    GetStockName: "/v1/stock/",
    // GetStockList: "/v1/stock/list",
    // CreateStockItem :'/v1/stock/create',
    // DeleteStockItem :'/v1/stock/delete',
    // AddStockItem :'/v1/stock/action/add',
    // UpdateStockItem :'/v1/stock/action/update',
    GetStockPreSubscribe: "/v1/stock/presubscribe",
    DeleteStockPreSubscribe: "/v1/stock/presubscribe/del",
    AddStockSubscribe : "/v1/stock/subscribe/add",
    GetStockSubscribe: "/v1/stock/subscribe",
    DeleteStockSubscribe : "/v1/stock/subscribe/delete",
    UpdateStockSubscribe : "/v1/stock/subscribe/update",
    GetStockOrder: "/v1/stock/order",
    GetStockTrade: "/v1/stock/trade",
    DeleteStockOrder: '/v1/stock/order/delete',
    CancelStockOrder: '/v1/stock/order/cancel',
    BuyStockOrder: '/v1/stock/order/buy',
    SellStockOrder: '/v1/stock/order/sell',
    GetStockFeatureById :'/v1/stock/feature/features',
    GetStockFeature2ById :'/v1/stock/feature2/features',
    SaveStockFeature :'/v1/stock/feature/save',
    QueryPosition : '/v1/stock/position/query',
    GetPositions : '/v1/stock/positions',
    GetVolumePeak: "/v1/stock/volume/peak",
}

export function getEndTimeList() {
    return axios.get(URL_BASE + Api.GetEndTimeList);
}

export function getStockName(stockId: string) {
    return axios.get(URL_BASE + Api.GetStockName+ stockId);
}

// export function getStockList( date : string ) {
//     return axios.get(URL_BASE + Api.GetStockList+"/" + date);
// }

// export function addStockItem(stockItem : string) {
//     return axios.post(URL_BASE + Api.CreateStockItem , stockItem);
// }

// export function deleteStockItem(stockItem: string) {
//     return axios.post(URL_BASE + Api.DeleteStockItem , stockItem);
// }

// export function addStockActionItem(stockItem: any ) {
//     console.log('addStockActionItem', stockItem)
//     return axios.post(URL_BASE + Api.AddStockItem , stockItem);
// }

// export function UpdateStockActionItem(stockItem: any) {
//     return axios.post(URL_BASE + Api.UpdateStockItem , stockItem);
// }
export function getStockPreSubscribe( date : string ) {
    return axios.get(URL_BASE + Api.GetStockPreSubscribe+"/" + date );
}

export function deleteStockPreSubscribe( date : string ) {
    return axios.post(URL_BASE + Api.DeleteStockPreSubscribe+"/" + date );
}



export function addStockSubscribeItem(stockItem: any) {
    return axios.post(URL_BASE + Api.AddStockSubscribe , stockItem);
}

/**
 * 
 * @param date 
 * @param direction 0: buy, 1: sell 
 * @returns 
 */
export function getStockSubscribe( date : string, direction: number ) {
    return axios.get(URL_BASE + Api.GetStockSubscribe+"/" + date + "/" + direction);
}

export function deleteStockSubscribeItem(stockItem: any) {
    return axios.post(URL_BASE + Api.DeleteStockSubscribe , stockItem);
}

export function updateStockSubscribeItem(stockItem: any) {
    return axios.post(URL_BASE + Api.UpdateStockSubscribe , stockItem);
}

/**
 * 
 * @param date 
 * @param direction 0: buy, 1: sell 
 * @returns 
 */
export function getStockOrder( date : string ,  direction: number) {
    return axios.get(URL_BASE + Api.GetStockOrder+"/" + date + "/" + direction);
}

 /**
  * 
  * @param date 
  * @param direction 0: buy, 1: sell 
  * @returns 
  */
export function getStockTrade( date : string ,  direction: number) {
    return axios.get(URL_BASE + Api.GetStockTrade+"/" + date + "/" + direction);
}

export function deleteStockOrderItem(stockItem: any) {
    return axios.post(URL_BASE + Api.DeleteStockOrder , stockItem);
}

export function cancelStockOrderItem(stockItem: any) {
    return axios.post(URL_BASE + Api.CancelStockOrder , stockItem);
}

export function buyStockOrderItem(stockItem: any) {
    return axios.post(URL_BASE + Api.BuyStockOrder , stockItem);
}

export function sellStockOrderItem(stockItem: any) {
    return axios.post(URL_BASE + Api.SellStockOrder , stockItem);
}

export function getStockFeatureById( stockId : string ) {
    return axios.get(URL_BASE + Api.GetStockFeatureById+"/" + stockId);
}

export function getStockFeature2ById( stockId : string ) {
    return axios.get(URL_BASE + Api.GetStockFeature2ById+"/" + stockId);
}

export function saveStockFeatures( data: any) {
    return axios.post(URL_BASE + Api.SaveStockFeature , data);
}

export function queryPosition(  ) {
    return axios.get(URL_BASE + Api.QueryPosition);
}

export function getPositions( date: string  ) {
    return axios.get(URL_BASE + Api.GetPositions + "/" + date );
}

export function getVolumePeak(date: string) {
    return axios.get(URL_BASE+ Api.GetVolumePeak + "/" + date);
}