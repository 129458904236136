import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import TradeView from '../views/TradeView.vue'
import IndexView from '../views/IndexView.vue'
import TradeResultView from '../views/TradeResultView.vue'
import FeatureStockView from '../views/FeatureStockView.vue'
import RankStockView from '../views/RankStockView.vue'
import PositionStockView from '../views/PositionStockView.vue'
import Stock925View from '../views/Stock925View.vue'
import BrokenUpLimit925View from '../views/BrokenUpLimit925View.vue'
import UploadRiseView from '../views/UploadRise.vue'
import UploadLowerView from '../views/UploadLower.vue'
import VolumePeak from '../views/VolumePeak.vue'

const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'index',
      component: IndexView,
    },
    {
      path: '/trade',
      name: 'trade',
      component: TradeView
    },
        {
      path: '/volume/peak',
      name: 'volumepeak',
      component: VolumePeak
    },
    {
      path: '/tradeview',
      name: 'tradeview',
      component: TradeResultView
    },
    {
      path: '/feature/stock',
      name: 'FeatureStock',
      component: FeatureStockView
    },
    {
      path: '/position',
      name: 'PositionStock',
      component: PositionStockView
    },
    {
      path: '/feature/rank',
      name: 'RankStock',
      component: RankStockView
    },
    {
      path: '/feature/stock925',
      name: 'Stock925',
      component: Stock925View
    },
    {
      path: '/uplimit/broken925',
      name: 'StockBroken925',
      component: BrokenUpLimit925View
    },
    {
      path: '/upload/rise',
      name: 'uploadRise',
      component: UploadRiseView
    },
    {
      path: '/upload/lower',
      name: 'uploadLower',
      component: UploadLowerView
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory('/stock/'),
  routes
})

export default router
