
  import {
    getStockFeatureById,
    getStockRankByFeature,
    getStockRank,
    parseRiseFeature,
    getStockInfo,
    getStockSouthNorth,
    getStockUpLimitInfo,
  } from '../api/api2';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from "element-plus";

  import * as echarts from 'echarts'

  interface Tree {
    label: string
    type: number
    name: string
    count: number
    id: string
    children ? : Tree[]
  }

  const handleNodeClick = (data: Tree) => {
    console.log(data)
  }


  const defaultProps = {
    children: 'children',
    label: 'label',
    type: 'type',
    name: 'name',
    id: 'id',
    count: 'count',

  }

  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        curDate: '',
        treeData: [] as Tree[],

        showEChart: true,


        d1to2: {
          preCount: 0,
          curCount: 0
        },
        d2to3: {
          preCount: 0,
          curCount: 0
        },
        d3to4: {
          preCount: 0,
          curCount: 0
        },
        d4to5: {
          preCount: 0,
          curCount: 0
        },
        stockData1to2: [] as any,
        stockData2to3: [] as any,
        stockData3to4: [] as any,
        stockData4to5: [] as any,
      }
    },


    async mounted() {
      console.log("Rankstockview")

      this.$data.curDate = this.getYYYYMMDD()


    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {
      headClass() { //表头居中显示
        return "text-align:center"
      },

      Fixed2(val: any) {
        return Number(val).toFixed(2);
      },

      ChangeToYi(val: any) {
        return val / 100000000.0;
      },

      cellStyle(
        row: any,
      ) {
        console.log('tableRowClassName')
        if (row.row.RiseType === 1 && (row.columnIndex == 2 || row.columnIndex == 3)) {
          return 'hilight3'
        } else if (row.row.RiseType === 2 && (row.columnIndex == 2 || row.columnIndex == 3)) {
          return 'hilight4'
        }
        return ''
      },

      getYYYYMMDD() {
        const nowDate = new Date()
        const year = nowDate.getFullYear()
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
        const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()

        return '' + year + month + day
      },

      getRiseType(price: number, priceDiff: number, rise: number): number {
        let type = 0
        if (rise < -5) {
          type = 1
        }
        const oldPrice = price - priceDiff
        const riseLimit: number = Number((oldPrice * 1.10).toFixed(4))
        const riseLimitDiff = riseLimit - oldPrice
        const riseDiff = riseLimitDiff - priceDiff
        const riseLimit2: number = Number((oldPrice * 1.20).toFixed(4))
        const riseLimitDiff2 = riseLimit2 - oldPrice
        const riseDiff2 = riseLimitDiff2 - priceDiff
        if (riseDiff <= 0.01 && riseDiff >= -0.01) {
          type = 2
        } else if (riseDiff2 <= 0.01 && riseDiff2 >= -0.01) {
          type = 2
        }

        return type
      },

      async handleNodeClick(data: Tree) {
        console.log(data)
        if (data.type == 0) {
          const res = await getStockFeatureById(data.id)
          console.log(res)

          if (res.data.code != 0) {
            ElMessage({
              message: "getStockFeatureById fail." + res.data.msg,
              type: "warning",
            });
            return;
          }

          data.children = [] as Tree[]
          for (const item of res.data.data) {
            data.children.push({
              label: item,
              type: 1,
              name: item,
              count: 0,
              id: item
            })
          }

        } else if (data.type == 1) {
          const res = await getStockRankByFeature(this.$data.curDate, data.name)
          console.log(res)
          data.children = [] as Tree[]
          for (const item of res.data.data) {
            data.children.push({
              label: item.Name + '  ' + item.Count,
              type: 2,
              name: item.Name,
              count: item.Count,
              id: item.StockId
            })
          }

          data.label = data.name + ' (' + data.children.length + ')'
        } else if (data.type == 2) {
          const res = await getStockFeatureById(data.id)
          console.log(res)

          if (res.data.code != 0) {
            ElMessage({
              message: "getStockFeatureById fail." + res.data.msg,
              type: "warning",
            });
            return;
          }



          data.children = [] as Tree[]
          var feature = ''
          for (const item of res.data.data) {
            feature = feature + "  " + item
          }

          data.label = data.name + '  ' + data.count + '  ' + feature

        }

      },

      async processRank() {
        let res = await parseRiseFeature(this.$data.curDate)
        console.log(res)


        res = await getStockRank(this.$data.curDate)
        console.log(res)

        if (res.data.code != 0) {
          ElMessage({
            message: "getStockRank fail." + res.data.msg,
            type: "warning",
          });
          return;
        }

        this.$data.treeData = [] as Tree[]
        for (const item of res.data.data) {
          this.$data.treeData.push({
            label: item.Name + '  ' + item.Count,
            type: 0,
            name: item.Name,
            count: item.Count,
            id: item.StockId
          })
        }

      },

      async processTable() {

        // 获取stockInfo
        const ret = await getStockInfo(this.$data.curDate, 7)
        console.log('getStockInfo ', ret)
        if (ret.data.code != 0) {
          ElMessage({
            message: "GetStockInfo失败." + ret.data.msg,
            type: "warning",
          });
          return
        }


        // 绘制数据
        const xAxis = []
        const series_y_lower = []
        const series_y_rise = []
        const series_y_broken = []
        const series_y_rise20 = []
        const series_y_multirise = []
        const series_y_onerise = []
        const series_y_tworise = []
        const series_y_riseheight = []
        const series_y_whole_up = []
        const series_y_whole_down = []
        const series_y_yesterday_uplimit_rise = []


        const datas = ret.data.data.Rise
        for (const item of datas) {
          console.log(item)
          xAxis.push(item.Date)
          // series_y_median.push(item.Median)
          // series_y_deviation.push(item.Deviation)
          series_y_lower.push(item.LowerCount)
          series_y_rise.push(item.RiseCount)
          series_y_broken.push(item.Broken)
          series_y_rise20.push(item.Rise20Count)
          series_y_multirise.push(item.MultiRiseCount)
          series_y_onerise.push(item.OneRiseCount)
          series_y_tworise.push(item.TwoRiseCount)
          series_y_riseheight.push(item.RiseHeight)
          series_y_whole_up.push(item.WholeUp)
          series_y_whole_down.push(item.WholeDown)
          series_y_yesterday_uplimit_rise.push(item.YesterdayUplimitRise)
        }

        // let myChart1 = echarts.init(document.getElementById("myChart1") as HTMLInputElement);
        let myChart2 = echarts.init(document.getElementById("myChart2") as HTMLInputElement);
        myChart2.setOption({
          title: {
            text: '涨跌停数据'
          },
          xAxis: {
            data: xAxis
          },
          yAxis: [{
              type: 'value',
              name: "单位",
            },
            {
              type: 'value',
              name: "单位",
            },
          ],
          series: [{
              name: "连板家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_multirise,
              color: "#D94DFF",
            },
            {
              name: "首板家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_onerise,
              color: "#FF1493",
            },
            {
              name: "2板家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_tworise,
              color: "#DC143C",
            },
            {
              name: "涨停高度",
              type: "line",
              yAxisIndex: 0,
              data: series_y_riseheight,
              color: "#CC5500",
            },
            {
              name: "20cm涨停家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_rise20,
              color: "#FF8C00",
            },
          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart2.on('click', function (params: any) {
          console.log(params)
        })



        // 大盘上涨下跌数
        let myChart5 = echarts.init(document.getElementById("myChart5") as HTMLInputElement);
        myChart5.setOption({
          title: {
            text: '大盘上涨下跌数'
          },
          xAxis: {
            data: xAxis
          },
          yAxis: [{
            type: 'value',
            name: "单位",
          }, ],
          series: [{
              name: "大盘上涨家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_whole_up,
              color: "#FF0000",
            },
            {
              name: "大盘下跌家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_whole_down,
              color: "#00FF00",
            },
          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart5.on('click', function (params: any) {
          console.log(params)
        })

        // 涨停跌停数,  炸板数
        let myChart6 = echarts.init(document.getElementById("myChart6") as HTMLInputElement);
        myChart6.setOption({
          title: {
            text: '大盘涨跌停及炸板'
          },
          xAxis: {
            data: xAxis
          },
          yAxis: [{
            type: 'value',
            name: "单位",
          }, ],
          series: [{
              name: "跌停家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_lower,
              color: "#800000",
            },
            {
              name: "涨停家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_rise,
              color: "#FF8C00",
            },
            {
              name: "炸板家数",
              type: "line",
              yAxisIndex: 0,
              data: series_y_broken,
              color: "#D94DFF",
            },
          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart6.on('click', function (params: any) {
          console.log(params)
        })


        // 昨日涨停数据
        let myChart3 = echarts.init(document.getElementById("myChart3") as HTMLInputElement);
        myChart3.setOption({
          title: {
            text: '昨日涨停数据(东财)'
          },
          xAxis: {
            data: xAxis
          },
          yAxis: [{
            type: 'value',
            name: "单位",
          }, ],
          series: [{
            name: "昨日涨停数据",
            type: "line",
            yAxisIndex: 0,
            data: series_y_yesterday_uplimit_rise,
            color: "#FF0000",
          }, ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart3.on('click', function (params: any) {
          console.log(params)
        })




        // 绘制数据
        const xAxis2 = []
        const series_y_avg_rise1 = []
        const series_y_avg_rise_multi = []

        const data_avg = ret.data.data.Avg
        for (const item of data_avg) {
          console.log(item)
          xAxis2.push(item.Date)
          series_y_avg_rise1.push(item.Rise1AVG)
          series_y_avg_rise_multi.push(item.RiseMultiAVG)
        }


        let myChart1 = echarts.init(document.getElementById("myChart1") as HTMLInputElement);
        myChart1.setOption({
          title: {
            text: '平均涨幅'
          },
          xAxis: {
            data: xAxis2
          },
          yAxis: {},
          series: [{
              name: "首板平均涨幅",
              type: "line",
              data: series_y_avg_rise1,
              color: "#800000",
            },
            {
              name: "连板平均涨幅",
              type: "line",
              data: series_y_avg_rise_multi,
              color: "#FF8C00",
            },

          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart1.on('click', function (params: any) {
          console.log(params)
        })




        // 北向数据
        // 获取stockInfo
        const ret_southnorth = await getStockSouthNorth(this.$data.curDate, 7)
        console.log('getStockSouthNorth ', ret_southnorth)
        if (ret.data.code != 0) {
          ElMessage({
            message: "getStockSouthNorth." + ret_southnorth.data.msg,
            type: "warning",
          });
          return
        }
        const xAxis4 = []
        const series_y_north_sh = []
        const series_y_north_sz = []
        const series_y_north_whole = []


        const data_southnorth = ret_southnorth.data.data
        for (const item of data_southnorth) {
          console.log(item)
          xAxis4.push(item.Date)
          series_y_north_sh.push(item.ShNetBuyAmt / 10000)
          series_y_north_sz.push(item.SzNetBuyAmt / 10000)
          series_y_north_whole.push(item.ShNetBuyAmt / 10000 + item.SzNetBuyAmt / 10000)
        }


        let myChart4 = echarts.init(document.getElementById("myChart4") as HTMLInputElement);
        myChart4.setOption({
          title: {
            text: '北向数据'
          },
          xAxis: {
            data: xAxis4
          },
          yAxis: {},
          series: [{
              name: "沪股通",
              type: "line",
              data: series_y_north_sh,
              color: "#FF1493",
            },
            {
              name: "深股通",
              type: "line",
              data: series_y_north_sz,
              color: "#FF8C00",
            },
            {
              name: "沪深总体",
              type: "line",
              data: series_y_north_whole,
              color: "#D94DFF",
            },

          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart4.on('click', function (params: any) {
          console.log(params)
        })


      },

      async processUpLimitInfo() {
        this.$data.stockData1to2 = []
        this.$data.d1to2.preCount = 0
        this.$data.d1to2.curCount = 0
        this.$data.stockData2to3 = []
        this.$data.d2to3.preCount = 0
        this.$data.d2to3.curCount = 0
        this.$data.stockData3to4 = []
        this.$data.d3to4.preCount = 0
        this.$data.d3to4.curCount = 0
        this.$data.stockData4to5 = []
        this.$data.d4to5.preCount = 0
        this.$data.d4to5.curCount = 0

        // 获取 uplimitinfo
        const ret = await getStockUpLimitInfo(this.$data.curDate)
        console.log('getStockUpLimitInfo ', ret)
        if (ret.data.code != 0) {
          ElMessage({
            message: "getStockUpLimitInfo失败." + ret.data.msg,
            type: "warning",
          });
          return
        }

        for (const item of ret.data.data) {

          for (const i in item.Stocks) {
            item.Stocks[i].RiseType = this.getRiseType(item.Stocks[i].CurClose, item.Stocks[i].CurClose - item
              .Stocks[i].PreClose, item.Stocks[i].Rise * 100)
          }

          console.log(item)
          if (item.PreLevel == 1) {
            this.$data.stockData1to2 = item.Stocks
            this.$data.d1to2.preCount = item.PreCount
            this.$data.d1to2.curCount = item.CurCount
          } else if (item.PreLevel == 2) {
            this.$data.stockData2to3 = item.Stocks
            this.$data.d2to3.preCount = item.PreCount
            this.$data.d2to3.curCount = item.CurCount
          } else if (item.PreLevel == 3) {
            this.$data.stockData3to4 = item.Stocks
            this.$data.d3to4.preCount = item.PreCount
            this.$data.d3to4.curCount = item.CurCount
          } else if (item.PreLevel == 4) {
            this.$data.stockData4to5 = item.Stocks
            this.$data.d4to5.preCount = item.PreCount
            this.$data.d4to5.curCount = item.CurCount
          }
        }



      },

      async onSearch() {
        console.log("curDate: ", this.$data.curDate)
        await this.processRank()
        await this.processTable()
        await this.processUpLimitInfo()
      }
    }
  });
