
  import {
    getEndTimeList,
    getStockName,
    getStockSubscribe,
    getStockPreSubscribe,
    deleteStockPreSubscribe,
    deleteStockSubscribeItem,
    getStockOrder,
    getStockTrade,
    cancelStockOrderItem,
    buyStockOrderItem,
    updateStockSubscribeItem,
    addStockSubscribeItem,
  } from "../api/api";
  import {
    defineComponent,
    ref
  } from "vue";
  import {
    ElMessage,
    ElTable
  } from "element-plus";
  import {
    id
  } from "element-plus/es/locale";

  // import XLSX from "xlsx";
  // import * as XLSX from 'xlsx/xlsx.mjs'
  import * as XLSX from 'xlsx'

  export default defineComponent({
    name: "HomeView",
    data() {
      return {
        showInputArea: true,
        actionData: [] as any,
        selActionData: [] as any,
        stockString: "",
        endTime: [],
        curEndTime: "",

        buyMethod: [{
          value: 0,
          label: '接力备选', // 涨停买入
        }, {
          value: 1,
          label: '竞价备选', // 直接买入
        }, {
          value: 2,
          label: '峰值监控', // 直接买入
        }],
        curBuyMethod: 0,

        curDate: "",
        curMoney: "",
        curCount: "",

        subscribeData: [] as any,
        selsubscribeData: [] as any,

        orderData: [] as any,
        selOrderData: [] as any,

        tradeData: [] as any,
        selTradeData: [] as any,

        timer: 0,

        upload_file: "",
      };
    },

    mounted() {
      console.log("home mounted.");
      getEndTimeList()
        .then((response) => {
          console.log(response);
          this.$data.endTime = response.data.data;
          this.$data.curEndTime = this.$data.endTime[this.$data.endTime.length - 1];
          console.log("curEndTime:", this.$data.curEndTime);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$data.curDate = this.getYYYYMMDD();

      this.onRefresh();
      // this.timer = window.setInterval(this.onRefresh, 1000)
      // console.log('timer:', this.timer);
    },

    beforeUnmount() {
      // clearInterval(this.timer)
      // this.$data.timer = 0
    },

    setup() {},

    methods: {
      tableRowClassName(
        row: any,
        index: Number,
      ) {
        console.log('tableRowClassName')
        if (row.row.Type === 0) {
          return 'warning-row'
        } else if (row.row.Type === 1) {
          return 'success-row'
        }
        return ''
      },
      onHideInputArea() {
        this.$data.showInputArea = !this.$data.showInputArea;
      },

      readExcel(e: any) {
        console.log('readExcel, ', e)
        let that = this
        const files = e.target.files
        if (files.length != 1) {
          ElMessage({
            message: "没有选择文件或者选择文件数量大于一个.",
            type: "warning",
          })
          return
        }

        if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
          ElMessage({
            message: "上传格式不正确,请上传xls或者xlsx格式",
            type: "warning"
          })
          return
        }

        that.upload_file = files[0].name
        const fileReader = new FileReader()

        fileReader.onload = (ev: any) => {
          console.log('readExcel  excel onLoad ', ev)

          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
            codepage: 936
          });
          const wsname = workbook.SheetNames[0]; //取第一张表

          const content = workbook.Sheets[wsname]
          const ws = XLSX.utils.sheet_to_json(content); //生成json表格内容
          const csv = XLSX.utils.sheet_to_csv(content); //生成json表格内容
          console.log('json: ', ws)
          console.log('json: ', csv)
          let ret = ''
          for (const row of csv.split('\n')) {
            console.log(row)
            const t = row.split(',')
            if (t.length > 0) {
              if (t[0].length == 6 && (+t[0]) > 0 && (+t[0]) < 700000) {
                ret = ret + t[0] + ','
              }
            }
          }
          if (ret.length > 0) {
            ret = ret.substring(0, ret.length - 1)
          }
          that.$data.stockString = ret

          return
        }

        fileReader.readAsBinaryString(files[0]);


      },
      oninput(val: any) {
        console.log(val);
        return val ? val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") : null;
      },
      async onParseStockAction() {
        console.log("onParseStockAction", this.$data.stockString);
        await deleteStockPreSubscribe(this.$data.curDate)
        console.log("curMoney:", this.$data.curMoney, "  curCount:", this.$data.curCount);
        var str = this.$data.stockString.replace(/\s/g, "");
        console.log(str);
        var stocks = str.split(/[,，]/);
        console.log(stocks);

        if (this.$data.curDate.length != 8) {
          ElMessage({
            message: "日期格式错误，检查是否是yyyymmdd格式.",
            type: "warning",
          });
          return;
        }
        if (this.$data.curEndTime.length != 8) {
          ElMessage({
            message: "时间格式错误，检查是否是hh:mm:ss格式.",
            type: "warning",
          });
          return;
        }

        var actionData = [];
        for (var i = 0; i < stocks.length; i++) {
          if (stocks[i].length != 6) {
            ElMessage({
              message: "格式错误:" + stocks[i],
              type: "warning",
            });
            break;
          }
          let curMoney = this.$data.curMoney ? this.$data.curMoney : 0;
          let curCount = this.$data.curCount ? this.$data.curCount : 0;

          if (curCount === 0 && curMoney === 0) {
            curMoney = 500000;
          }

          const retStock = await getStockName(stocks[i])
          let stockName = ""
          if (retStock.data.code == 0) {
            stockName = retStock.data.data.Name
          }

          actionData.push({
            StockId: stocks[i],
            StockName: stockName,
            Date: this.$data.curDate,
            EndTime: this.$data.curEndTime,
            Money: curMoney,
            Count: curCount,
            Price: 0,
            Direction: 0,
            Type: this.$data.curBuyMethod,
            Strategy: 0,
          });

          this.$data.actionData = actionData;

          (this.$refs.actionDataTableRef as InstanceType <
            typeof ElTable >
          ).toggleAllSelection();
        }

        this.onRefresh();
      },

      async onUpdateStockAction() {
        const items = await getStockPreSubscribe(this.$data.curDate)
        const {
          code,
          data,
          msg,
        } = items.data
        if (code != 0) {
          ElMessage({
            message: "getStockPreSubscribe fail:" + code + " " + msg,
            type: "warning",
          });
        }
        var actionData = [];

        for (var i = 0; i < data.length; i++) {
          if (data[i].StockId.length != 6) {
            ElMessage({
              message: "格式错误:" + data[i].StockId,
              type: "warning",
            });
            break;
          }
          let curMoney = this.$data.curMoney ? this.$data.curMoney : 0;
          let curCount = this.$data.curCount ? this.$data.curCount : 0;

          if (curCount === 0 && curMoney === 0) {
            curMoney = 500000;
          }

          const retStock = await getStockName(data[i].StockId)
          let stockName = ""
          if (retStock.data.code == 0) {
            stockName = retStock.data.data.Name
          }

          actionData.push({
            StockId: data[i].StockId,
            StockName: stockName,
            Date: this.$data.curDate,
            EndTime: this.$data.curEndTime,
            Money: curMoney,
            Count: curCount,
            Price: 0,
            Direction: 0,
            Type: this.$data.curBuyMethod,
            Strategy: 0,
          });

        }


        this.$data.actionData = actionData;

        (this.$refs.actionDataTableRef as InstanceType <
          typeof ElTable >
        ).toggleAllSelection();
      },

      // async onAddStockAction() {
      //   console.log("onAddStockAction");

      //   for (const item of this.$data.selActionData) {
      //     console.log("onAddStockAction", item);
      //     item.Price = parseFloat(item.Price);
      //     item.Money = parseInt(item.Money);
      //     item.Count = parseInt(item.Count);
      //     const res = await addStockActionItem(item);
      //     if (res.data.code !== 0) {
      //       ElMessage({
      //         message: "添加动作 fail:" + res.data.code + " " + res.data.msg,
      //         type: "warning",
      //       });
      //     }
      //   }
      // },

      async onAddStockAction() {


        console.log("onAddStockAction: buy method:", this.$data.curBuyMethod);

        for (const item of this.$data.selActionData) {
          console.log("onAddStockAction", item);
          item.Price = parseFloat(item.Price);
          item.Money = parseInt(item.Money);
          item.Count = parseInt(item.Count);
          const res = await addStockSubscribeItem(item);
          if (res.data.code !== 0) {
            console.log("onUpdateStockAction fail");
            ElMessage({
              message: "更新动作 fail:" + res.data.code + " " + res.data.msg,
              type: "warning",
            });
          }
        }

        setTimeout(this.updateSubscribeData, 1000)
      },

      handleActionDataSelChange(e: any) {
        console.log("handleActionDataSelChange", e);
        this.$data.selActionData = e;
      },

      handleSubscribeSelChange(e: any) {
        console.log("handleSubscribeSelChange", e);
        this.$data.selsubscribeData = e;
      },

      handleOrderSelChange(e: any) {
        console.log("handleOrderSelChange", e);
        this.$data.selOrderData = e;
      },

      onCancelSubscribes() {
        for (const item of this.$data.selsubscribeData) {
          this.cancelSubscribeItem(item);
        }
      },

      onCancelSubscribeItem(index: Number, row: any) {
        console.log("onCancelSubscribe", index, row);
        this.cancelSubscribeItem(row);
      },

      /**
       * 
       * @param index 
       * @param row 
       * @param opration 0: 监控买入  1: 直接买入 
       */
      onExecuteSubscribeItem(index: Number, row: any, operation: Number) {
        console.log("onExecuteSubscribeItem", index, row);
        if (row.Type === 0 || row.Type === 1) {
          this.executeSubscribeItem(row, operation);
        } else {
          this.executeSubscribeItem(row, row.Type);
        }

        setTimeout(this.updateSubscribeData, 1000)
        setTimeout(this.updateOrderData, 1000)
      },
      onHoldSubscribeItem(index: Number, row: any) {
        console.log("onHoldSubscribeItem", index, row);
        this.holdSubscribeItem(row);

        setTimeout(this.updateSubscribeData, 1000)
      },

      onCancelOrders() {
        for (const item of this.$data.selOrderData) {
          this.cancelOrderItem(item);
        }

        setTimeout(this.updateOrderData, 1000)
      },

      onCancelOrderItem(index: Number, row: any) {
        console.log("onCancelOrder", index, row);
        this.cancelOrderItem(row);

        setTimeout(this.updateOrderData, 1000)
      },

      onBuyOrderItem(index: Number, row: any) {
        console.log("onBuyOrderItem", index, row);
        this.buyOrderItem(row);

        setTimeout(this.updateOrderData, 1000)
      },

      cancelSubscribeItem(data: any) {
        deleteStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.curEndTime,
          Action: "cancel_subscribe",
          Money: data.Money,
          Count: data.Count,
          Type: data.Type,
          Direction: data.Direction,
        });
      },

      /**
       * 
       * @param data 
       * @param operation 0: 监控涨停买入   1: 直接买入
       */
      executeSubscribeItem(data: any, operation: Number) {
        updateStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.EndTime,
          Money: data.Money,
          Count: data.Count,
          Price: data.Price,
          Type: operation,
          Strategy: 1,
          Direction: data.Direction,
        });
      },
      holdSubscribeItem(data: any) {
        updateStockSubscribeItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          EndTime: data.EndTime,
          Money: data.Money,
          Count: data.Count,
          Price: data.Price,
          Type: data.Type,
          Strategy: 0,
          Direction: data.Direction,
        });
      },

      cancelOrderItem(data: any) {
        cancelStockOrderItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          OrderAction: "cancel",
          Price: data.Price,
          Count: data.Count,
          Strategy: data.Strategy,
          OrderSysId: data.OrderSysId,
          Direction: data.Direction,
        });
      },

      buyOrderItem(data: any) {
        buyStockOrderItem({
          StockId: data.StockId,
          Date: this.$data.curDate,
          OrderAction: "buy",
          Price: data.Price,
          Count: data.Count,
          Strategy: data.Strategy,
          OrderSysId: data.OrderSysId,
          Direction: data.Direction,
        });
      },

      onRefresh() {
        this.updateSubscribeData();
        this.updateOrderData();
        // this.updateTradeData();
      },


      getYYYYMMDD() {
        const nowDate = new Date();
        const year = nowDate.getFullYear();
        const month =
          nowDate.getMonth() + 1 < 10 ?
          "0" + (nowDate.getMonth() + 1) :
          nowDate.getMonth() + 1;
        const day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();

        return "" + year + month + day;
      },

      async updateSubscribeData() {
        const ret = await getStockSubscribe(this.$data.curDate, 0);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }

          this.$data.subscribeData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },

      async updateOrderData() {
        const ret = await getStockOrder(this.$data.curDate, 0);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }

          this.$data.orderData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },

      async updateTradeData() {
        const ret = await getStockTrade(this.$data.curDate, 0);
        const {
          code,
          data,
          msg
        } = ret.data;
        if (code === 0) {
          for (const item of data) {
            item.StockName = await this.getStockNameById(item.StockId)
          }

          this.$data.tradeData = data;
        } else {
          ElMessage({
            message: "getStockOrder fail:" + code + " " + msg,
            type: "warning",
          });
        }
      },

      async getStockNameById(id: string): Promise < string > {
        const retStock = await getStockName(id)
        let stockName = ""
        if (retStock.data.code == 0) {
          stockName = retStock.data.data.Name
        }
        return stockName
      },
    },
  });
