
  import {
    getRisePrice925,
    addStock925Median,
    getStock925Median,
    getStockIndex,
    getLatestRiseDate,
    getUpLimitVolumeInfo,
  } from '../api/api2';
  import {
    addStockPresubscribe,
  } from '../api/api3';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from 'element-plus';


  import * as echarts from 'echarts'
  import {
    range
  } from 'lodash-es';

  interface StockDataType {
    Date: string
    StockId: string
    StockName: string
    Price: number
    PriceDiff: number
    Rise: number
    Volume: number
    Money: number
    FirstRise: number
    LastRise: number
    RiseFeature: string
    RiseDays: number
    Money_BeforeMoney: number
    BeforeVolume: number
    BeforeMoney: number
    Shown: boolean
  }

  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        curDate: '',
        riseDate: '',
        stock925Data: [] as any,
        selStock925Data: [] as any,

        middleData: 0,
        deviationData: 0,

        showEChart: true,

        timer: 0,

        checkAll: ref(false),
        isIndeterminate: ref(true),
        checkedFeature: ref([] as any),

        checkRiseDaysAll: ref(false),
        isRiseDaysIndeterminate: ref(true),
        checkedRiseDays: ref([] as any),
        allFeatures: [] as any,
        allRiseDays: [] as any
      }
    },


    async mounted() {


      this.$data.curDate = this.getYYYYMMDD()
      const res = await getLatestRiseDate()
      if (res.data.code !== 0) {
        this.$data.riseDate = this.getYestodayYYYYMMDD()
      } else {
        const riseDate = res.data.data
        if (riseDate === this.$data.curDate) {
          this.$data.riseDate = this.getYestodayYYYYMMDD()
        } else {
          this.$data.riseDate = riseDate
        }
      }

      // this.timer = window.setInterval(this.onRefresh, 1000)
      // console.log('timer:', this.timer);

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {

      cellStyle(
        row: any,
      ) {
        console.log('tableRowClassName')
        if (row.row.RiseType === 1 && (row.columnIndex == 4 || row.columnIndex == 7)) {
          return 'hilight1'
        } else if (row.row.RiseType === 2 && (row.columnIndex == 4 || row.columnIndex == 7)) {
          return 'hilight2'
        } else if (row.row.isMorning === 1 && (row.columnIndex == 11)) {
          return 'hilight3'
        } else if (row.row.type_1_2 == 1 && (row.columnIndex == 15)) {
          return 'hilight4'
        } else if (row.row.type_2_3 == 1 && (row.columnIndex == 14)) {
          return 'hilight4'
        }

      },

      tableRowClassName(row: any, index: any) {
        console.log(row.row.RiseFeature)
        for (const v in this.$data.checkedFeature) {
          if (row.row.RiseFeature.indexOf(this.$data.checkedFeature[v]) != -1) {
            if (this.$data.checkedRiseDays.length >= 2) {
              return ''
            }

            if (this.$data.checkedRiseDays.length === 0) {
              return 'hidden-row'
            }
            if (this.$data.checkedRiseDays[0] === '1-->2') {
              if (row.row.RiseDays === 1) {
                return ''
              }
            } else {
              if (row.row.RiseDays >= 2) {
                return ''
              }
            }
          }
        }


        return 'hidden-row'
      },

      getYYYYMMDD() {
        const nowDate = new Date()
        const year = nowDate.getFullYear()
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
        const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()

        return '' + year + month + day
      },

      getYestodayYYYYMMDD() {
        var day1 = new Date();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        const year = day1.getFullYear()
        const month = day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1
        const day = day1.getDate() < 10 ? '0' + day1.getDate() : day1.getDate()
        return '' + year + month + day
      },

      Fixed2(val: any) {
        return Number(val).toFixed(2);
      },

      ChangeToYi(val: any) {
        return val / 100000000.0;
      },

      handleStock925DataSelChange(e: any) {
        console.log("handleActionDataSelChange", e);
        this.$data.selStock925Data = e;
      },

      async handleCheckAllChange(val: boolean) {
        console.log('handleCheckAllChange')
        this.$data.checkedFeature = ref(val ? this.$data.allFeatures : [])
        this.$data.isIndeterminate = ref(false)
      },

      async handleCheckedFeaturesChange(value: string[]) {
        console.log('handleCheckedFeaturesChange')
        const checkedCount = value.length
        this.$data.checkAll = ref(checkedCount === this.$data.allFeatures.length)
        this.$data.isIndeterminate = ref(checkedCount > 0 && checkedCount < this.$data.allFeatures.length)
      },

      async defaultFeatureCheckBox() {
        this.$data.checkedFeature = ref(this.$data.allFeatures)
        this.$data.isIndeterminate = ref(true)
      },


      async handleCheckAllRiseDaysChange(val: boolean) {
        console.log('handleCheckAllChange')
        this.$data.checkedRiseDays = ref(val ? this.$data.allRiseDays : [])
        this.$data.isRiseDaysIndeterminate = ref(false)
      },

      async handleCheckedRiseDaysChange(value: string[]) {
        console.log('handleCheckedFeaturesChange')
        const checkedCount = value.length
        this.$data.checkRiseDaysAll = ref(checkedCount === this.$data.allRiseDays.length)
        this.$data.isRiseDaysIndeterminate = ref(checkedCount > 0 && checkedCount < this.$data.allRiseDays.length)
      },


      async defaultRiseDaysCheckBox() {
        this.$data.checkedRiseDays = ref(this.$data.allRiseDays)
        this.$data.isRiseDaysIndeterminate = ref(true)
      },

      refreshShowStockdata(stockData: StockDataType[]) {
        console.log('refreshShowStockdata')
        // const shownFeatures = this.$data.checkedFeature
        // for (const item in stockData) {
        //   item.Shown = false
        //   for (const t in shownFeatures) {
        //     if (item.RiseFeature.index(t) != -1) {
        //       item.Shown = true
        //     }
        //   }
        // }
        // console.log(this.$data.stock925Data)
      },

      getVolume(data: any, stockId: string) {
        for (const item of data) {
          if (item.StockId == stockId) {
            return {
              volume: item.Volume,
              volume1: item.Volume1,
              count: item.Count,
              count1: item.Count1,
            }
          }
        }
        return {
          volume: 0,
          volume1: 0,
          count: 0,
          count1: 0,
        }

      },

      getAllFeatures(data: any) {
        // 计算涨幅类型
        const ret = []
        const allFeature = new Map()
        for (const item of data) {
          item.RiseType = this.getRiseType(item.Price, item.PriceDiff, item.Rise)
          item.isMorning = this.getMorningType(item.LastRise)
          item.type_1_2 = this.getType_1_2(item.Volume_BeforeVolume, item)
          item.type_2_3 = this.getType_2_3(item.Volume_Before_925Volume, item)
          const f = item.RiseFeature.split('+')
          for (const t of f) {
            allFeature.set(t, 1)
          }
        }
        const allF = allFeature.keys()
        for (const f of allF) {
          ret.push(f)
        }

        console.log(ret)
        return ret
      },

      async onSearch() {
        this.$data.stock925Data = []
        this.$data.middleData = 0
        this.$data.deviationData = 0

        console.log("curDate: ", this.$data.curDate)
        const res = await getRisePrice925(this.$data.riseDate, this.$data.curDate)
        console.log(res)
        let {
          code,
          data,
          msg
        } = res.data;
        if (code != 0 || data === null) {
          ElMessage({
            message: "getStock925 fail." + res.data.msg,
            type: "warning",
          });
          return;
        }

        const res2 = await getUpLimitVolumeInfo(this.$data.riseDate)
        if (res2.data.code != 0 || res2.data.data === null) {
          ElMessage({
            message: "getUpLimitVolumeInfo fail." + res2.data.msg,
            type: "warning",
          });
          return;
        }


        for (const item of data) {
          item.Money_BeforeMoney = item.Money * 1.0 / item.BeforeMoney
          item.Volume_Before_925Volume = item.Volume / item.Before925Volume

          const {
            volume,
            volume1,
            count,
            count1,
          } = this.getVolume(res2.data.data, item.StockId)
          if (volume > 0) {
            item.Volume_BeforeVolume = item.Volume / volume
            item.Volume_BeforeVolume1 = item.Volume_BeforeVolume * count / 240
            // item.Volume_BeforeVolume1 = item.Volume / volume1
          } else {
            item.Volume_BeforeVolume = -1
            item.Volume_BeforeVolume1 = -1
          }

        }
        this.$data.stock925Data = data;

        const f = this.getAllFeatures(data)
        this.$data.allFeatures = f
        if (this.$data.allFeatures.length > 0) {
          this.defaultFeatureCheckBox()
        }

        this.$data.allRiseDays = ['1-->2', '2-->3']
        this.defaultRiseDaysCheckBox()

        this.$nextTick(function () {
          (this.$refs.stock925DataTableRef as InstanceType <
            typeof ElTable > ).toggleAllSelection()
        })

      },

      async onCaculate() {
        // 计算中位数
        console.log('onCaculate')
        const data = this.$data.selStock925Data
        const len = data.length;
        if (len % 2 == 0) {
          this.$data.middleData = (data[len / 2 - 1].Rise + data[len / 2].Rise) * 0.5
        } else {
          this.$data.middleData = data[Math.floor(len / 2)].Rise
        }

        // 计算标准差
        let squareDiff = 0
        let totalRise = 0
        for (const item of data) {
          totalRise = totalRise + item.Rise
        }
        const average = totalRise / len
        for (const item of data) {
          squareDiff = squareDiff + (item.Rise - average) * (item.Rise - average)
        }

        this.$data.deviationData = Math.sqrt(squareDiff / (len - 1))

        // 保存中位数和标准差
        const res = await addStock925Median({
          Date: this.$data.curDate,
          Median: this.$data.middleData,
          Deviation: this.$data.deviationData,
        })
        console.log(res)
        if (res.data.code !== 0) {
          ElMessage({
            message: "保存中位数标准差失败." + res.data.msg,
            type: "warning",
          });
          return
        } else {
          ElMessage({
            message: "保存中位数标准差成功.",
            type: "warning",
          });
        }


        // 获取中位数和标准差
        // let ret = await getStock925Median(this.$data.curDate, 7)
        // console.log('getStock925Median, ', ret)
        // if (ret.data.code != 0) {
        //   ElMessage({
        //     message: "获取中位数标准差失败." + ret.data.msg,
        //     type: "warning",
        //   });
        //   return
        // }

        // 获取中位数和标准差
        let ret = await getStock925Median(this.$data.curDate, 15)
        console.log('getStock925Median, ', ret)
        if (ret.data.code != 0) {
          ElMessage({
            message: "获取中位数标准差失败." + ret.data.msg,
            type: "warning",
          });
          return
        } else {

          // 绘制数据
          const xAxis = []
          const series_y_median = []
          const series_y_deviation = []

          for (const item of ret.data.data) {
            console.log(item)
            xAxis.push(item.Date)
            series_y_median.push(item.Median)
            series_y_deviation.push(item.Deviation)
          }

          let myChart1 = echarts.init(document.getElementById("myChart1") as HTMLInputElement);
          myChart1.setOption({
            xAxis: {
              data: xAxis
            },
            yAxis: {},
            series: [{
                name: "中位数",
                type: "line",
                data: series_y_median,
                color: "#f00",
              },
              {
                name: "标准差",
                type: "line",
                data: series_y_deviation,
                color: "#00f",
              }
            ],
            tooltip: {
              trigger: 'item'
            }
          });

          myChart1.on('click', function (params: any) {
            console.log(params)
          })

        }


        // 读取index数据
        ret = await getStockIndex(this.$data.curDate, 15)
        console.log('getStockIndex', ret)
        // 绘制数据
        const xIndexAxis = []
        const series_y_925 = []
        const series_y_1500 = []

        const indexdatas = ret.data.data
        for (const item of indexdatas) {
          console.log(item)
          xIndexAxis.push(item.Date)
          // series_y_median.push(item.Median)
          // series_y_deviation.push(item.Deviation)
          series_y_925.push(this.Fixed2(this.ChangeToYi(item.Money925)))
          series_y_1500.push(this.Fixed2(this.ChangeToYi(item.Money1500)))
        }

        let myChart3 = echarts.init(document.getElementById("myChart3") as HTMLInputElement);
        myChart3.setOption({
          xAxis: {
            data: xIndexAxis
          },
          yAxis: [{
              type: 'value',
              name: "单位(亿)",
            },
            {
              type: 'value',
              name: "单位(亿)",
            },
          ],
          series: [{
              name: "9:25",
              type: "line",
              yAxisIndex: 0,
              data: series_y_925,
              color: "#FF1493",
            },
            {
              name: "15:00",
              type: "line",
              yAxisIndex: 1,
              data: series_y_1500,
              color: "#FF8C00",
            }
          ],
          tooltip: {
            trigger: 'item'
          }
        });

        myChart3.on('click', function (params: any) {
          console.log(params)
        })

      },

      onHideEChart() {
        this.$data.showEChart = !this.$data.showEChart
      },

      async onAddPreSubscribe(index: Number, row: any) {
        console.log("onAddPreSubscribe", index, row);
        const res = await addStockPresubscribe(this.$data.curDate, row.StockId)
        let {
          code,
        } = res.data;
        if (code != 0) {
          ElMessage({
            message: "addStockPresubscribe fail." + code + ' ' + res.data.msg,
            type: "warning",
          });
          return;
        }

      },


      getRiseType(price: number, priceDiff: number, rise: number): number {
        let type = 0
        if (rise > 5) {
          type = 1
        }
        const oldPrice = price - priceDiff
        const riseLimit: number = Number((oldPrice * 1.10).toFixed(2))
        const riseLimitDiff = riseLimit - oldPrice

        const riseDiff = riseLimitDiff - priceDiff
        if (riseDiff <= 0.00001 && riseDiff >= -0.00001) {
          type = 2
        }
        return type
      },

      getMorningType(lastRise: string) {
        if (lastRise.trim() <= '11:30:00') {
          return 1
        }
        return 0
      },

      getType_1_2(v: number, item: any) {
        if ((v > 1.0) && item.isMorning && (item.RiseType != 2) && (item.RiseDays === 1) && (item.LastRise.trim() !=
            '09:30:00')) {
          return 1
        }
        return 0
      },

      getType_2_3(v: number, item: any) {
        if ((v > 1.5) && item.isMorning && (item.RiseType != 2) && (item.RiseDays >= 2) && (item.RiseDays <= 4)) {
          return 1
        }
        return 0
      }


    }
  });
