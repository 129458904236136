
  import {
    getStockFeatureById,
    getStockName,
    saveStockFeatures,
  } from '../api/api';
  import {
    defineComponent,
    ref
  } from 'vue';
  import {
    ElMessage,
    ElTable
  } from "element-plus";


  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        curStock: '',
        curFeature: '',
        featureData: [] as any,
        selFeatureData: [] as any,
        curStockName: '',

        timer: 0,
      }
    },


    mounted() {

    },

    beforeUnmount() {

      // clearInterval(this.timer)
      // this.$data.timer = 0

    },

    setup() {},


    methods: {
      headClass() { //表头居中显示
        return "text-align:center"
      },

      onDeleteItem(index: Number, row: any) {
        console.log("onCancelSubscribe", index, row);
        this.$data.featureData.splice(index, 1)
      },

      onAddItem() {
        this.$data.featureData.push({
          Feature: this.$data.curFeature
        })
      },

      async onSaveItem() {
        var features = []
        for (const item of this.$data.featureData) {
          features.push(item.Feature)
        }

        const res = await saveStockFeatures({
          StockId: this.$data.curStock,
          Features: features
        })
        if (res.data.code == 0) {
          // success
          console.log("saveStockFeatures succeed.")
        } else {
          console.log("saveStockFeatures fail.")
          ElMessage({
            message: res.data.msg,
            type: "warning",
          });
        }

      },


      async onSearch() {
        if (!this.$data.curStock) {
          ElMessage({
            message: "请输入股票代码.",
            type: "warning",
          });
          return;
        }
        const res = await getStockFeatureById(this.$data.curStock)
        if (res.data.code == 0) {
          this.$data.featureData = res.data.data
          this.$data.featureData = []
          for (const item of res.data.data) {
            this.$data.featureData.push({
              Feature: item
            })
          }

        } else {
          ElMessage({
            message: "getStockFeatureById失败." + res.data.msg,
            type: "warning",
          });
          return;
        }

        const res2 = await getStockName(this.$data.curStock)
        this.$data.curStockName = ''
        if (res2.data.code == 0) {
          this.$data.curStockName = res2.data.data.Name

        } else {
          ElMessage({
            message: "getStockName失败." + res.data.msg,
            type: "warning",
          });
          return;
        }

      }
    }
  });
