import axios from "axios";

let URL_BASE = "http://localhost:8887"

if (process.env.NODE_ENV == 'development') {
    URL_BASE = "http://localhost:8887" 
  } else if (process.env.NODE_ENV == 'production') {
    // URL_BASE = "http://62.234.19.18:8887" 
    URL_BASE = "http://192.168.101.85:8887" 
  }

var Api = {
    GetStockName: "/v2/stock/",
    GetRisePrice925: "/v2/stock/riseprice925",
    GetStockBrokenUpLimit925: "/v2/stock/brokenuplimit925",
    GetUpLimitVolumeInfo: "/v2/stock/uplimit/volume",
    AddRiseLimit : "/v2/stock/riselimit/add",
    AddLowerLimit : "/v2/stock/lowerlimit/add",
    AddStock925Median : "/v2/stock925/median/add",
    GetStock925Median :'/v2/stock925/median',
    Uploadfile:'/v2/upload/rise',
    UploadLowerfile:'/v2/upload/lower',
    GetLatestRiseDate: '/v2/stock/rise/date/latest',
  
    GetStockInfo: '/v2/stock/info',
    GetStockIndex: "/v2/stock/index",
    GetStockSouthNorth: '/v2/stock/southnorth',
    GetStockUpLimitInfo: '/v2/stock/uplimit/info',


    GetStockRankByFeature: '/v2/stock/rank/stocks',
    GetStockFeatureById :'/v2/stock/feature/features',
    GetStockRank: '/v2/stock/rank/stock',
    ParseRiseFeature: '/v2/parse/rise/feature',

    GetRiseLimt: '/v2/stock/riselimit'

    // GetStockList: "/v1/stock/list",
    // CreateStockItem :'/v1/stock/create',
    // DeleteStockItem :'/v1/stock/delete',
    // AddStockItem :'/v1/stock/action/add',
    // UpdateStockItem :'/v1/stock/action/update',

}

export function getStockName(stockId: string) {
  return axios.get(URL_BASE + Api.GetStockName+ stockId);
}

export function getRisePrice925(date1: string, date2 :string) {
    console.log("api2: getStock925" + date1 + " " + date2)
    return axios.get(URL_BASE + Api.GetRisePrice925+ "/" + date1 + "/" + date2 );
}

export function getStockBrokenUpLimit925(date1: string, date2 :string) {
  console.log("api2: getStock925" + date1 + " " + date2)
  return axios.get(URL_BASE + Api.GetStockBrokenUpLimit925+ "/" + date1 + "/" + date2 );
}


export function getUpLimitVolumeInfo(date: string ) {
  console.log("api2: getStock925" + date )
  return axios.get(URL_BASE + Api.GetUpLimitVolumeInfo+ "/" + date  );
}

export function addRiseLimit(data :any) {
    console.log("api2: addRiseLimit")
    return axios.post(URL_BASE + Api.AddRiseLimit,data  );
}

export function addLowerLimit(data :any) {
  console.log("api2: addLowerLimit")
  return axios.post(URL_BASE + Api.AddLowerLimit,data  );
}

export function addStock925Median(data : any) {
  console.log("api2: addStock925Median")
  return axios.post(URL_BASE + Api.AddStock925Median, data  );
}

export function getStock925Median(date: string, count :number) {
  console.log("api2: getStock925Median" + date + " " + count)
  return axios.get(URL_BASE + Api.GetStock925Median+ "/" + date + "/" + count );
}

export function getStockInfo(date: string, count :number) {
  console.log("api2: getStock925Median" + date + " " + count)
  return axios.get(URL_BASE + Api.GetStockInfo+ "/" + date + "/" + count );
}

export function getStockIndex(date: string, count :number) {
  console.log("api2: getStockIndex" + date + " " + count)
  return axios.get(URL_BASE + Api.GetStockIndex+ "/" + date + "/" + count );
}

export function getStockSouthNorth(date: string, count :number) {
  console.log("api2: GetStockSouthNorth" + date + " " + count)
  return axios.get(URL_BASE + Api.GetStockSouthNorth+ "/" + date + "/" + count );
}

export function getStockUpLimitInfo(date: string ) {
  console.log("api2: GetStockUpLimitInfo" + date  )
  return axios.get(URL_BASE + Api.GetStockUpLimitInfo+ "/" + date );
}

export function uploadFile(data : any) {
  console.log("api2: uploadFile")
  return axios.post(URL_BASE + Api.Uploadfile, data  );
}

export function uploadLowerFile(data : any) {
  console.log("api2: uploadLowerFile")
  return axios.post(URL_BASE + Api.UploadLowerfile, data  );
}

export function getLatestRiseDate( ) {
  console.log("api2: getLatestRiseDate"  )
  return axios.get(URL_BASE + Api.GetLatestRiseDate  );
}

export function getStockRankByFeature( date: string, feature: string) {
  return axios.get(URL_BASE + Api.GetStockRankByFeature + "/" + date + "/" + feature);
}

export function getStockRank( date: string ) {
  return axios.get(URL_BASE + Api.GetStockRank + "/" + date );
}

export function getStockFeatureById( stockId : string ) {
  return axios.get(URL_BASE + Api.GetStockFeatureById+"/" + stockId);
}

export function parseRiseFeature( date : string ) {
  return axios.get(URL_BASE + Api.ParseRiseFeature+"/" + date);
}

export function getRiseLimt( date : string ) {
  return axios.get(URL_BASE + Api.GetRiseLimt+"/" + date);
}
